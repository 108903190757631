import React from 'react';
import AppRoute from './component/AppRoute';


const App = () => {
  return (
    <>
      <AppRoute />
    </>
  );
}

export default App;