import React, { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  FormControl,
  InputRightElement,
  FormErrorMessage,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import { loginEpos } from "../service";
import { useNavigate } from "react-router-dom";

const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

const SignIn = ({setTruncatedUserName}) => {
  
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleShowClick = () => setShowPassword(!showPassword);

  const [credential, setCredential] = useState({
    userName: "",
    password: "",
  });
  // const truncatedUserName = credential.userName.slice(0, 12);
  // console.log("credential: ", credential);

  const [formError, setFormError] = useState({});
  const navigate = useNavigate();

  const onChageHandler = (e) => {
    setCredential({ ...credential, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const errors = {};
  
    Object.keys(credential).forEach((key) => {
      if (!credential[key]) {
        errors[key] = "Field is required.";
      }
    });
  
    setFormError(errors);
  
    if (Object.keys(errors).length > 0) {
      return;
    }
  
    setIsLoading(true);
  
    const payload = {
      userName: credential.userName,
      password: credential.password,
    };

    setTruncatedUserName(credential.userName.slice(0, 12))
  
    try {
      const response = await loginEpos(payload);
  
      if (response.status === 200) {
        toast({
          title: "Login Successful!",
          description: "You have successfully logged in",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top-right"
        });
        navigate('/epos');
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="gray.200"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        {/* <Avatar bg="teal.500" /> */}
        <Box minW={{ base: "90%", md: "320px" }}>
          <form>
            <Stack
              spacing={4}
              p="1rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
            >
              <Center>
                <Heading color="teal" as="h2" size="xl" pb={5}>
                  TDR EPOS{" "}
                </Heading>
              </Center>
              <FormControl isInvalid={formError.userName}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  <Input
                    type="userName"
                    placeholder="user name"
                    name="userName"
                    vaue={credential.userName || ""}
                    onChange={onChageHandler}
                  />
                </InputGroup>
                {formError.userName && (
                  <FormErrorMessage>{formError.userName}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={formError.password}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    color="gray.300"
                    children={<CFaLock color="gray.300" />}
                  />
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    value={credential.password || ""}
                    onChange={onChageHandler}
                  />

                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                      {showPassword ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                {formError.password && (
                  <FormErrorMessage>{formError.password}</FormErrorMessage>
                )}
              </FormControl>
              <Center>
                {isLoading ? (
                  <Spinner mt="24px" size="lg" />
                ) : (
                  <Button
                    borderRadius={0}
                    type="submit"
                    variant="solid"
                    colorScheme="teal"
                    width="full"
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                )} 
              </Center>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}

export default SignIn;