import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "../pages/SignIn";
import PosForm from "../pages/SingleEpos";
import Layout from "./Layout";


const AppRoute = () => {
  const [truncatedUserName, setTruncatedUserName] = useState("");
  const handleLogin = (username) => {
    // console.log("username: ", username);
    setTruncatedUserName(username.slice(0, 12));
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SignIn
            handleLogin={handleLogin}
            setTruncatedUserName={setTruncatedUserName}
          />
        }
      />
      <Route
        path="/epos"
        element={<Layout truncatedUserName={truncatedUserName} />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoute;
