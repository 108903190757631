import {
  Box,
  Button,
  Input,
  Text,
  useToast,
  Stack,
  FormControl,
  FormLabel,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { multipleEpos } from "../service";

const UploadEpos = ({ isLoading, setIsLoading }) => {
  const [jsonData, setJsonData] = useState(null);
  const [file, setFile] = useState(null);
  const [successItems, setSuccessItems] = useState([]);
  const [failedItems, setFailedItems] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleFileUpload = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.type === "application/json") {
      const reader = new FileReader();
      reader.onload = (e) => {
        let result = e.target.result;
        try {
          const data = JSON.parse(result);
          setJsonData(data);
          setFile(uploadedFile);
          toast({
            title: "File uploaded successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } catch (error) {
          toast({
            title: "Error parsing JSON file.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      };
      reader.readAsText(uploadedFile);
    } else {
      toast({
        title: "Please upload a valid JSON file.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSendFile = async (event) => {
    event.preventDefault();
    if(!file) return;

    if (jsonData) {
      setIsLoading(true);
      const formattedData = jsonData.map((item) => ({
        licenseeIdNo: item.Licensee_Id_No,
        datetimeStamp: item.Datetime_Stamp,
        gtin: item.GTIN,
        packSize: parseInt(item.Pack_Size),
        mrp: parseFloat(item.MRP),
        quantity: item.Quantity,
      }));

      try {
        const response = await multipleEpos(formattedData);
        // console.log(" response uploaded: ", response);
        
        const { successfulData, unsuccessfulData, message } = response?.response?.data;

        
        if (response?.response?.data) {
          setSuccessItems(successfulData || []);
          setFailedItems(
            (unsuccessfulData || []).map((item) => ({
              item,
              errorMessage: message,
            }))
          );
          toast({
            title: "File processing completed.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else {
          setSuccessItems(successfulData || []);
          setFailedItems(
            unsuccessfulData.map((item) => ({
              item,
              errorMessage: message,
            }))
          );
          toast({
            title: "Some items failed to process.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        setJsonData(null);
        setFile(null);
        onOpen();
        setIsLoading(false);
      } catch (error) {
        setSuccessItems([]);
        setFailedItems(
          formattedData.map((item) => ({ item, errorMessage: error.message }))
        );
        toast({
          title: "Error processing file.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setJsonData(null);
        setFile(null);
        onOpen();
        setIsLoading(false);
      }
    } else {
      toast({
        title: "No file selected.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleResendFailedItems = async () => {
    setIsLoading(true);
    const failedData = failedItems.map((failedItem) => failedItem.item);

    try {
      const response = await multipleEpos(failedData);
      if (response.status === 200) {
        setSuccessItems((prev) => [...prev, ...failedData]);
        setFailedItems([]);
        toast({
          title: "Failed items resent successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        setFailedItems(
          failedData.map((item) => ({ item, errorMessage: response.message }))
        );
        toast({
          title: "Some items still failed to process.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      setFailedItems(
        failedData.map((item) => ({ item, errorMessage: error.message }))
      );
      toast({
        title: "Error resending failed items.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <Box as="form" onSubmit={handleSendFile}>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Upload JSON File</FormLabel>
          <Flex alignItems="center" height="100%">
            <Input
              type="file"
              accept=".json"
              onChange={handleFileUpload}
              className="file-input"
              style={{ display: "none" }}
              id="file-upload"
            />
            <Button
              as="label"
              htmlFor="file-upload"
              cursor="pointer"
              style={{ width: "100%" }}
            >
              Choose file
            </Button>
          </Flex>
        </FormControl>

        {jsonData && jsonData.length > 0 && (
          <Box bg="white" boxShadow="md" p={2} maxH="340px" overflowY="auto">
            {jsonData.map((item, index) => (
              <Box
                key={index}
                p={2}
                fontSize="14px"
                borderBottom="1px solid #ddd"
                cursor="pointer"
              >
                <Flex justifyContent="space-between">
                  <Box fontSize="14px">
                    <Stack>
                      <Text>Licensee Id No : {item.Licensee_Id_No}</Text>
                      <Text>GTIN : {item.GTIN}</Text>
                      <Text>Pack Size : {item.Pack_Size}</Text>
                      <Text>MRP : {item.MRP}</Text>
                      <Text>Quantity : {item.Quantity}</Text>
                    </Stack>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Box>
        )}

        <Button
          type="submit"
          colorScheme="blue"
          variant="solid"
          isLoading={isLoading}
        >
          Send
        </Button>
      </Stack>

      {/* Modal code */}
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>File Processing Results</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="bold">Successful Items:</Text>
            <Box
              maxHeight="300px"
              overflowY="auto"
              borderWidth="1px"
              borderRadius="md"
            >
              {successItems.length > 0 ? (
                successItems.map((item, index) => (
                  <Box key={index} p={2} borderBottom="1px solid #ddd">
                    <Text>Licensee Id No: {item.licenseeIdNo}</Text>
                    <Text>GTIN: {item.gtin}</Text>
                    <Text>Pack Size: {item.packSize}</Text>
                    <Text>MRP: {item.mrp}</Text>
                    <Text>Quantity: {item.quantity}</Text>
                  </Box>
                ))
              ) : (
                <Text>No successful items.</Text>
              )}
            </Box>
            <Text fontWeight="bold" mt={4}>
              Failed Items:
            </Text>
            <Box
              maxHeight="300px"
              overflowY="auto"
              borderWidth="1px"
              borderRadius="md"
            >
              {failedItems.length > 0 ? (
                failedItems.map((failedItem, index) => (
                  <Box key={index} p={2} borderBottom="1px solid #ddd">
                    <Text>Licensee Id No: {failedItem.item.licenseeIdNo}</Text>
                    <Text>GTIN: {failedItem.item.gtin}</Text>
                    <Text>Pack Size: {failedItem.item.packSize}</Text>
                    <Text>MRP: {failedItem.item.mrp}</Text>
                    <Text>Quantity: {failedItem.item.quantity}</Text>
                    <Text color="red">Error: {failedItem.errorMessage}</Text>
                  </Box>
                ))
              ) : (
                <Text>No failed items.</Text>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleResendFailedItems}
              isLoading={isLoading}
            >
              Resend Failed Items
            </Button>
            <Button colorScheme="gray" onClick={onClose} ml={3}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default UploadEpos;
