import React, { useRef } from "react";
import {
  Input,
  Button,
  Stack,
  FormControl,
  FormErrorMessage,
  Text,
  Center,
  Spinner,
  Box,
  FormLabel,
} from "@chakra-ui/react";

const SingleEpos = ({
  posData,
  showFailedText,
  showMatchingText,
  showSuccessText,
  formError,
  searchResults,
  handleCreate,
  isLoading,
  onScrollHandler,
  onChangeHandler,
  itemNameChangeHandler,
  handleSelectItem,
  itemInputRef,
}) => {
  return (
    <Box as="form" onSubmit={handleCreate}>
      <Stack spacing={4}>
        <FormControl isInvalid={formError.itemName}>
          <FormLabel>Item Name</FormLabel>
          <Input
            ref={itemInputRef}
            size="sm"
            type="text"
            name="itemName"
            placeholder="Search Item Here..."
            value={posData.itemName}
            onChange={itemNameChangeHandler}
            autoComplete="off"
          />
          <FormErrorMessage>{formError.itemName}</FormErrorMessage>
          {searchResults.length > 0 && (
            <Box bg="white" boxShadow="md" p={2} maxH="340px" overflowY="auto">
              {searchResults.map((item, index) => (
                <Box
                  key={index}
                  p={2}
                  fontSize="14px"
                  borderBottom="1px solid #ddd"
                  cursor="pointer"
                  onClick={() => handleSelectItem(item)}
                >
                  <Text>Item Name : {item.itemName}</Text>
                  <Text>GTIN : {item.gtin}</Text>
                  <Text>Pack Size : {item.packSize}</Text>
                  <Text>MRP : {item.mrp}</Text>
                </Box>
              ))}
            </Box>
          )}
        </FormControl>

        <FormControl isInvalid={formError.gtin}>
          <FormLabel>GTIN</FormLabel>
          <Input
            size="sm"
            type="text"
            name="gtin"
            value={posData.gtin}
            onChange={onChangeHandler}
          />
          <FormErrorMessage>{formError.gtin}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formError.packSize}>
          <FormLabel>Pack Size</FormLabel>
          <Input
            size="sm"
            type="text"
            name="packSize"
            value={posData.packSize}
            onChange={onChangeHandler}
          />
          <FormErrorMessage>{formError.packSize}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formError.mrp}>
          <FormLabel>MRP</FormLabel>
          <Input
            size="sm"
            type="text"
            name="mrp"
            value={posData.mrp}
            onChange={onChangeHandler}
          />
          <FormErrorMessage>{formError.mrp}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={formError.quantity}>
          <FormLabel>Quantity</FormLabel>
          <Input
            size="sm"
            type="text"
            name="quantity"
            value={posData.quantity}
            onChange={onChangeHandler}
            onScroll={onScrollHandler}
          />
          <FormErrorMessage>{formError.quantity}</FormErrorMessage>
        </FormControl>

        {showSuccessText && (
          <Box color="green.500">POS created successfully!</Box>
        )}
        {showFailedText && <Box color="red.500">Failed to create POS!</Box>}
        {showMatchingText && (
          <Box color="orange.500">No matching data found!</Box>
        )}

        <Button
          type="submit"
          colorScheme="blue"
          variant="solid"
          isLoading={isLoading}
        >
          Send
        </Button>
        
      </Stack>
    </Box>
  );
};

export default SingleEpos;
