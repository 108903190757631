import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import PosForm from "../pages/SingleEpos";
import MultipleEpos from "../pages/MultipleEpos";

import { createEpos, searchAllItems } from "../service";
import debounce from "lodash.debounce";
import UploadEpos from "../pages/UploadEpos";

const Layout = ({ truncatedUserName }) => {
  const [isLoading, setIsLoading] = useState(false);
  let todaysDate = new Date();
  const getCurrentDatetime = () => {
    const year = todaysDate.getFullYear();
    const month = String(todaysDate.getMonth() + 1).padStart(2, "0");
    const day = String(todaysDate.getDate()).padStart(2, "0");
    const hours = String(todaysDate.getHours()).padStart(2, "0");
    const minutes = String(todaysDate.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const [posData, setPosData] = useState({
    licenseeIdNo: truncatedUserName,
    datetimeStamp: getCurrentDatetime(),
    itemName: "",
    gtin: "",
    packSize: "",
    mrp: "",
    quantity: "",
  });
  const itemInputRef = useRef(null);
  const [formError, setFormError] = useState({});
  const [showSuccessText, setShowSuccessText] = useState(false);
  const [showFailedText, setShowFailedText] = useState(false);
  const [showMatchingText, setShowMatchingText] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  // console.log("searchResults: ", searchResults);

  const handleSelectItem = (selectedItem) => {
    setPosData({
      ...posData,
      itemName: selectedItem.itemName,
      gtin: selectedItem.gtin,
      packSize: selectedItem.packSize,
      mrp: selectedItem.mrp,
      // quantity: selectedItem.quantity,
    });
    if (selectedItem) {
      setSearchResults([]);
    }
  };

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    let newValue = value;

    if (name === "packSize" || name === "mrp" || name === "quantity") {
      newValue = value.replace(/\D/, "");
    }

    setPosData({ ...posData, [name]: newValue });
  };

  const onScrollHandler = (e) => {
    e.preventDefault();
  };

  const itemNameChangeHandler = async (event) => {
    const itemName = event.target.value;
    setPosData({
      ...posData,
      itemName,
    });
    debouncedSearch(itemName);

    if (!itemName) {
      setPosData((prevData) => ({
        ...prevData,
        gtin: "",
        packSize: "",
        mrp: "",
        quantity: "",
      }));
      setSearchResults([]);
    }
  };

  useEffect(() => {
    if (searchResults) {
      //   console.log("in useeffect if while search");
      setPosData({
        ...posData,
        gtin: searchResults?.gtin,
        packSize: searchResults?.packSize,
        mrp: searchResults?.mrp,
        // quantity: searchResults?.quantity,
      });
      // setSearchResults([]);
    } else {
      //   console.log("in useeffect else while search");

      setPosData((prevData) => ({
        ...prevData,
        gtin: "",
        packSize: "",
        mrp: "",
        quantity: "",
      }));
    }
  }, [searchResults.itemName]);

  const debouncedSearch = debounce(async (itemName) => {
    try {
      const response = await searchAllItems(itemName);
      // console.log("debouncedSearch response: ", response);
      if (itemName) {
        if (response?.data?.data) {
          setSearchResults(response.data.data);
        } else {
          setSearchResults([]);
        }
        if (response.response.data.message === "No matching data found") {
          setShowMatchingText(true);
          setTimeout(() => {
            setShowMatchingText(false);
          }, 2000);
        }
      } else {
        setPosData((prevData) => ({
          ...prevData,
          gtin: "",
          packSize: "",
          mrp: "",
          quantity: "",
        }));
      }
    } catch (error) {
      console.error("Error searching items:", error);
    }
  }, 1000);

  // console.log("posData layout: ", posData);

  const handleCreate = async (e) => {
    e.preventDefault();

    let formattedDateTime = "";
    if (!posData.datetimeStamp) {
      formattedDateTime = formatDate(todaysDate);
    }

    const errors = {};
    Object.keys(posData).forEach((key) => {
      // console.log("key ---> ", key);
      if (!posData[key]) {
        errors[key] = "Field is required.";
      }
    });

    setFormError(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setIsLoading(true);

    try {
      const payload = {
        itemName: posData.itemName,
        licenseeIdNo: truncatedUserName || posData.licenseeIdNo,
        datetimeStamp: posData.datetimeStamp || formattedDateTime,
        gtin: posData.gtin,
        packSize: posData.packSize,
        mrp: posData.mrp,
        quantity: posData.quantity,
      };

      const response = await createEpos(payload);

      if (response.status === 200) {
        setShowSuccessText(true);
        setTimeout(() => {
          setShowSuccessText(false);
        }, 2000);

        setPosData((prevData) => ({
          ...prevData,
          itemName: "",
          gtin: "",
          packSize: "",
          mrp: "",
          quantity: "",
        }));
        itemInputRef.current.focus();
      } else {
        setShowFailedText(true);
        setTimeout(() => {
          setShowFailedText(false);
        }, 2000);
      }
    } catch (err) {
      console.error("Error:", err);
      setShowFailedText(true);
      setTimeout(() => {
        setShowFailedText(false);
      }, 2000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Flex
      minH="100vh"
      align="center"
      justify="center"
      flexDirection="column"
      bg="gray.100"
      p={4}
    >
      <Stack spacing={6} w="full" maxW="container.md" fontSize="15px">
        <Box bg="white" p={6} rounded="md" boxShadow="lg">
          <Stack spacing={4}>
            <FormControl isInvalid={formError.licenseeIdNo}>
              <FormLabel>Licensee No :</FormLabel>
              <Input
                size="sm"
                type="text"
                name="licenseeIdNo"
                value={posData.licenseeIdNo || ""}
                onChange={onChangeHandler}
              />
              <FormErrorMessage>{formError.licenseeIdNo}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={formError.datetimeStamp}>
              <FormLabel>Date :</FormLabel>
              <Input
                size="sm"
                type="datetime-local"
                name="datetimeStamp"
                value={posData.datetimeStamp}
                onChange={onChangeHandler}
              />
              <FormErrorMessage>{formError.datetimeStamp}</FormErrorMessage>
            </FormControl>
          </Stack>

          <Tabs variant="solid-rounded" size="sm" isFitted marginTop={4}>
            <TabList>
              <Tab>Single Entry</Tab>
              <Tab>Multiple Entry</Tab>
              <Tab>Upload</Tab>
            </TabList>
            <TabPanels marginTop={4}>
              <TabPanel p={0}>
                <PosForm
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  posData={posData}
                  setPosData={setPosData}
                  showSuccessText={showSuccessText}
                  showFailedText={showFailedText}
                  showMatchingText={showMatchingText}
                  formError={formError}
                  searchResults={searchResults}
                  handleSelectItem={handleSelectItem}
                  itemNameChangeHandler={itemNameChangeHandler}
                  onChangeHandler={onChangeHandler}
                  onScrollHandler={onScrollHandler}
                  handleCreate={handleCreate}
                  itemInputRef={itemInputRef}
                />
              </TabPanel>
              <TabPanel p={0}>
                <MultipleEpos
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  posData={posData}
                  setPosData={setPosData}
                  showSuccessText={showSuccessText}
                  setShowSuccessText={setShowSuccessText}
                  showFailedText={showFailedText}
                  setShowFailedText={setShowFailedText}
                  showMatchingText={showMatchingText}
                  searchResults={searchResults}
                  handleSelectItem={handleSelectItem}
                  itemNameChangeHandler={itemNameChangeHandler}
                  onChangeHandler={onChangeHandler}
                  onScrollHandler={onScrollHandler}
                  handleCreate={handleCreate}
                  truncatedUserName={truncatedUserName}
                />
              </TabPanel>
              <TabPanel p={0}>
                <UploadEpos isLoading={isLoading} setIsLoading={setIsLoading} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Stack>
    </Flex>
  );
};
export default Layout;
