import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
   <BrowserRouter>
      <ChakraProvider>
         {/* <Provider store={store}> */}
            <App />
         {/* </Provider> */}
      </ChakraProvider>

   </BrowserRouter>
);
