import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { getAllItems, multipleEpos } from "../service";
import debounce from "lodash.debounce";

const getCurrentDatetime = () => {
  const now = new Date();
  return now.toISOString().slice(0, 16);
};

const formatDate = (dateTimeString) => {
  const date = new Date(dateTimeString);
  return date.toLocaleString();
};

const MultipleEpos = ({
  posData,
  setPosData,
  isLoading,
  setIsLoading,
  showSuccessText,
  setShowSuccessText,
  showFailedText,
  setShowFailedText,
  truncatedUserName,
}) => {
  const [allItems, setAllItems] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const itemInputRef = useRef(null);

  useEffect(() => {
    setPosData((prevData) => ({
      ...prevData,
      licenseeIdNo: truncatedUserName,
      datetimeStamp: getCurrentDatetime(),
    }));
  }, [setPosData, truncatedUserName]);

  const fetchAllItems = async () => {
    setIsLoading(true);
    try {
      const allItemsResponse = await getAllItems();
      setAllItems(allItemsResponse?.data?.data || []);
      setSearchResults(allItemsResponse?.data?.data || []);
    } catch (error) {
      console.error("Error fetching items. Please try again later.", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllItems();
    itemInputRef.current.focus();
  }, []);

  const handleQuantityChange = (e, id) => {
    const { value } = e.target;
    const newQuantity = value.replace(/\D/, "");
    setSelectedItems((prevItems) => ({
      ...prevItems,
      [id]: newQuantity,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validSelectedItems = Object.entries(selectedItems)
      .filter(([, quantity]) => quantity)
      .map(([id, quantity]) => {
        const item = allItems.find((item) => item._id === id);
        return {
          licenseeIdNo: truncatedUserName || posData.licenseeIdNo,
          datetimeStamp: formatDate(posData.datetimeStamp),
          itemName: item.itemName,
          gtin: item.gtin,
          packSize: item.packSize,
          mrp: item.mrp,
          quantity,
        };
      });

    setIsLoading(true);
    try {
      const response = await multipleEpos(validSelectedItems);
      if (response.status === 200) {
        setShowSuccessText(true);
        setTimeout(() => setShowSuccessText(false), 2000);
        // setSearchResults([]);
        setPosData((prevData) => ({
          ...prevData,
          itemName: "",
          gtin: "",
          packSize: "",
          mrp: "",
          quantity: "",
          datetimeStamp: getCurrentDatetime(),
        }));
        setSelectedItems({});
        // fetchAllItems();
        itemInputRef.current.focus();
      } else {
        setShowFailedText(true);
        setTimeout(() => setShowFailedText(false), 2000);
      }
    } catch (error) {
      console.error("Error creating multiple epos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      if (query) {
        const filteredItems = allItems.filter((item) =>
          item.itemName.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(filteredItems);
      } else {
        setSearchResults(allItems);
      }
    }, 300),
    [allItems]
  );

  const itemNameChangeHandler = (event) => {
    const itemName = event.target.value;
    setPosData((prevData) => ({
      ...prevData,
      itemName,
    }));
    debouncedSearch(itemName);
  };

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Item Name : </FormLabel>
          <Input
            ref={itemInputRef}
            size="sm"
            type="text"
            name="itemName"
            placeholder="Search Item Here..."
            value={posData.itemName}
            onChange={itemNameChangeHandler}
          />
        </FormControl>

        {searchResults.length > 0 && (
          <Box bg="white" boxShadow="md" p={2} maxH="340px" overflowY="auto">
            {searchResults.map((item) => (
              <Box
                key={item._id}
                p={2}
                fontSize="14px"
                borderBottom="1px solid #ddd"
                cursor="pointer"
              >
                <Flex justifyContent="space-between">
                  <Box fontSize="14px">
                    <Stack>
                      <Text>Item Name : {item.itemName}</Text>
                      <Text>GTIN : {item.gtin}</Text>
                      <Text>Pack Size : {item.packSize}</Text>
                      <Text>MRP : {item.mrp}</Text>
                    </Stack>
                  </Box>
                  <Flex
                    flexDirection="column"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    fontSize="14px"
                  >
                    <FormControl>
                      <Stack alignItems="flex-end">
                        <Text>Quantity : </Text>
                        <Input
                          w="50%"
                          size="sm"
                          variant="outline"
                          value={selectedItems[item._id] || ""}
                          onChange={(e) => handleQuantityChange(e, item._id)}
                          onScroll={(e) => e.preventDefault()}
                        />
                      </Stack>
                    </FormControl>
                  </Flex>
                </Flex>
              </Box>
            ))}
          </Box>
        )}
        {showSuccessText && (
          <Center mt="24px">
            <Text color="teal">You have successfully created POS.</Text>
          </Center>
        )}

        {showFailedText && (
          <Center mt="24px">
            <Text color="red">There's an error creating POS.</Text>
          </Center>
        )}

        <Button
          type="submit"
          colorScheme="blue"
          variant="solid"
          isLoading={isLoading}
          isDisabled={Object.keys(selectedItems).length === 0}
        >
          Send
        </Button>
      </Stack>
    </Box>
  );
};

export default MultipleEpos;
