import axios from "axios";
import { url } from "./constants";
import axiosInstance from "./utils/axiosInstance";

export const createEpos = async (payload) => {
  try {
    const apiURL = `${url}/create-pos`;
    const createEposData = await axiosInstance.post(apiURL, payload);
    return createEposData;
  } catch (error) {
    return error;
  }
};

export const loginEpos = async (payload) => {
  try {
    const apiURL = `${url}/login`;
    const loginEposData = await axiosInstance.post(apiURL, payload);
    const token = loginEposData.data.token;
    document.cookie = `token=${token}; path=/;`;
    return loginEposData;
  } catch (error) {
    return error;
  }
};

export const searchAllItems = async (itemName) => {
  try {
    const apiURL = `${url}/get-item-name/?itemName=${itemName}`;
    const getItemData = await axiosInstance.get(apiURL);
    return getItemData;
  } catch (error) {
    return error;
  }
};

export const getAllItems = async () => {
  try {
    const apiURL = `${url}/get-item-name`;
    const getItemData = await axiosInstance.get(apiURL);
    return getItemData;
  } catch (error) {
    return error;
  }
};

export const multipleEpos = async (posData) => {
  try {
    const apiURL = `${url}/multiple-epos`;
    const multipleEposData = await axiosInstance.post(apiURL, posData);
    return multipleEposData;
  } catch (error) {
    return error;
  }
};
